import React, { useState, useEffect } from "react";
import { Link } from "gatsby"
import { Container, Row, Col,Form,Button } from "react-bootstrap"
import Trustpilot from '../../../images/trustpilot.svg'
import Slider from "react-slick";
import "./Banner.scss";
import parse from 'html-react-parser';
import { getSrc } from "gatsby-plugin-image"
import { AltSiteName } from "../../utils"
import SearchInputBox from '../../predictive-search/search-areas';
import { navigate } from "@reach/router";
import $ from 'jquery';
// import TrustPilotBadge from "../../trust-pilot-badge";
import loadable from '@loadable/component'

const settings = {
    dots: true,
    speed: 800,
    infinite: false,
    arrows: false,
    mobileFirst: true,
    centerMode: false,
    responsive: [
        {
            breakpoint:3020,
            settings: {
              arrows: false,
              slidesToShow: 1
            }
          },
        {
          breakpoint: 1199,
          settings: {
            arrows: false,
            slidesToShow: 1
          }
        },
        {
          breakpoint:991,
          settings: {
            dots: false,
            slidesToShow: 1
          }
        },
        {
          breakpoint: 400,
          settings: {
            arrows: false,
            slidesToShow: 1
          }
        }
      ]
  };

const Banner = (props) => {

  const TrustPilotBadge = loadable(() => import('../../trust-pilot-badge'))
  TrustPilotBadge.preload()

  useEffect(() => {
    $(".banner-content .react-autosuggest__input").on("keyup", function(){
      var opencontainer = $('.banner-content .react-autosuggest__suggestions-container--open').length;
      setTimeout(function(){ 
      if(opencontainer > 0) {
        $('.maincarousel').addClass('banner-search-reveal')
      } else {
        $('.maincarousel').removeClass('banner-search-reveal')
      }
      }, 500);
    });
    $('#buy-checkbox').attr('checked',true);
    $('#buy-checkbox-one').attr('checked',true);
    $('#buy-checkbox-two').attr('checked',true);

    let url = "/";
    $(".search_btn").click(function() {
        $(window).scrollTop(0);
        var ptype = $("input[name=search-property]:checked").val()
        var searcValue = $(".banner-content .searchVal").val().split(', ').join('-').replace(/ /g, "-").replace(/'/g, "").toLowerCase();
        if(ptype === 'buy') {
            if(searcValue !== "") {
                url = searcValue+"/"
            } else {
                url = "essex-and-kent/"
            }
            navigate("/properties-for-sale/"+url);
        }
        if(ptype === 'rent') {
          if(searcValue !== "") {
              url = searcValue+"/"
          } else {
              url = "essex-and-kent/"
          }
          navigate("/properties-to-rent/"+url);
      }

    });
    
    $(".search_btn_one").click(function() {
      $(window).scrollTop(0);
      var ptype = $(".slick-active.slick-current input[name=search-property-one]:checked").val()      
      var searcValue = $(".slick-active.slick-current .banner-content .searchVal").val().split(', ').join('-').replace(/ /g, "-").replace(/'/g, "").toLowerCase();
      if(ptype === 'buy') {
          if(searcValue !== "") {
              url = searcValue+"/"
          } else {
              url = "essex-and-kent/"
          }
          navigate("/properties-for-sale/"+url);
      }
      if(ptype === 'rent') {
        if(searcValue !== "") {
            url = searcValue+"/"
        } else {
            url = "essex-and-kent/"
        }
        navigate("/properties-to-rent/"+url);
    }

  });
  $(".search_btn_two").click(function() {
    $(window).scrollTop(0);
    var ptype = $(".slick-active.slick-current input[name=search-property-two]:checked").val()
    var searcValue = $(".slick-active.slick-current .banner-content .searchVal").val().split(', ').join('-').replace(/ /g, "-").replace(/'/g, "").toLowerCase();    if(ptype === 'buy') {
        if(searcValue !== "") {
            url = searcValue+"/"
        } else {
            url = "essex-and-kent/"
        }
        navigate("/properties-for-sale/"+url);
    }
    if(ptype === 'rent') {
      if(searcValue !== "") {
          url = searcValue+"/"
      } else {
          url = "essex-and-kent/"
      }
      navigate("/properties-to-rent/"+url);
  }

});

  });
    return (
        <React.Fragment>
            <div className="val-banner-head responsive-val-head d-block d-lg-none">
              <Link to ="/property-valuation/">
              CLICK HERE TO BOOK A <span>FREE</span> VALUATION
              </Link>
            </div>
            <div className="banner maincarousel">
            <Slider className="banner-imgslider d-block" {...settings}>
            {props.slides && props.slides.map((slide,index) => {
            return<>
            <div className="banner-item">
                <Container>
                     <Row>
                        <Col lg='9'>
                        <div className="val-banner-head d-lg-block d-none">
                            <Link to ="/property-valuation/">
                            CLICK HERE TO BOOK A <span>FREE</span> VALUATION
                            </Link>
                            </div>
                            <div className="banner-content">

                            <div className="banner-block">
                           
                            <h1>{slide.Slider_Title}</h1>
                            {parse(slide.Slider_Content)}

                            </div>
                            {index === 0 &&
                                <Form className={`search-banner-${index}`} id ={`search-banner-form${index}`}>
                                        {/* <Form.Control type="text" placeholder="Search by postcode or area" /> */}
                                        <SearchInputBox />
                                        <div className="custom-checkbox">
                                            <Form.Group controlId="buy-checkbox">
                                                <Form.Check type="radio" name="search-property" label="Buy" value="buy" />
                                            </Form.Group>
                                            <Form.Group  controlId="rent-checkbox">
                                                <Form.Check type="radio" name="search-property" label="Rent" value="rent" />
                                            </Form.Group>
                                        </div>
                                        <Button className="search_btn" variant="primary" type="button">Find a property</Button>
                                </Form>
                              } 
                              {index === 1 &&
                                <Form className={`search-banner-${index}`} id ={`search-banner-form${index}`}>
                                        {/* <Form.Control type="text" placeholder="Search by postcode or area" /> */}
                                        <SearchInputBox />
                                        <div className="custom-checkbox one">
                                            <Form.Group controlId="buy-checkbox-one">
                                                <Form.Check type="radio" name="search-property-one" label="Buy" value="buy" />
                                            </Form.Group>
                                            <Form.Group  controlId="rent-checkbox-one">
                                                <Form.Check type="radio" name="search-property-one" label="Rent" value="rent" />
                                            </Form.Group>
                                        </div>
                                        <Button className="search_btn_one" variant="primary" type="button">Find a property</Button>
                                </Form>
                              } 
                              {index === 2 &&
                                <Form className={`search-banner-${index}`} id ={`search-banner-form${index}`}>
                                        {/* <Form.Control type="text" placeholder="Search by postcode or area" /> */}
                                        <SearchInputBox />
                                        <div className="custom-checkbox two">
                                            <Form.Group controlId="buy-checkbox-two">
                                                <Form.Check type="radio" name="search-property-two" label="Buy" value="buy" />
                                            </Form.Group>
                                            <Form.Group  controlId="rent-checkbox-two">
                                                <Form.Check type="radio" name="search-property-two" label="Rent" value="rent" />
                                            </Form.Group>
                                        </div>
                                        <Button className="search_btn_two" variant="primary" type="button">Find a property</Button>
                                </Form>
                              } 
                                <div className="star-rating">
                                    <TrustPilotBadge/>
                                    {/* <img src={Trustpilot} alt="trustpilot"/> */}
                                </div>
                            </div>
                            </Col>
                            
                    </Row>
                    <div className="banner-img mlr-minus">
                            <picture>                                
                                <img src={getSrc(slide.Slider_Image.url_sharp) } alt={slide.Slider_Image.alternativeText?slide.Slider_Image.alternativeText:slide.Slider_Title+AltSiteName} />
                          </picture>
                              
                 </div>
                 </Container>
                 </div>
                 </>
                 })}
                 
                
                </Slider>
            </div>
        </React.Fragment>
    )
}
export default Banner
