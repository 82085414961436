import React, { useState, useEffect } from "react";
import Helmet from "react-helmet"
import LoadExternalScript from "../Components/utils/load-external-script";
import TrustpilotReviews from "../Components/trustpilot"

const TrustPilotSlider = (props) => {
    //console.log('aaaaaaa')
    //const TrustPilotScriptsJs = 'https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
    //const [showbadge, setShowbadge] = useState(false)
    return (      
            <React.Fragment>
            {/* <LoadExternalScript 
              src={TrustPilotScriptsJs}
              // async="true"
              // appendScriptTo="head"
              onload={console.log('aaaaaaaaaa load',showbadge),()=>setShowbadge(true),console.log('aaaaaaaaaa load1',showbadge)}
            /> */}
             {/* <div class="trustpilot-widget" data-locale="en-GB" data-template-id="5419b6ffb0d04a076446a9af" data-businessunit-id="5ca72f2628a1c800015b7cb2" data-style-height="20px" data-style-width="450px" data-theme="light" data-text-color="#e0e0e0">
            <a href="https://uk.trustpilot.com/review/www.balgoresproperty.co.uk" target="_blank" rel="noopener">Trustpilot</a>
            </div> */}

      <TrustpilotReviews
        language="en"
        culture="US"
        theme="light"
        width="100%"
        height="240px"
        template="54ad5defc6454f065c28af8b"
        business="5ca72f2628a1c800015b7cb2"
        username="balgoresproperty"
        data-text-color="#e0e0e0"
        stars="4,5"
      />

            </React.Fragment>
        )
    }
    export default TrustPilotSlider;
