import React, { useState, useEffect } from "react";
import { Link } from "gatsby"
import Select from "react-dropdown-select";
import ScrollAnimation from 'react-animate-on-scroll';
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap"
import "../Components/CarrierLanding/PropertyTransaction/Transaction.scss"
import "../Components/CarrierLanding/PropertyDetails/PropertyDetails.scss"
import NewsCategory from "./NewsCategoryList"
import {AllNews, SearchNews, FeaturedNews} from "../queries/common_use_query"
import DateFormat from "./format-date";
import PropertyWorthModule from "./how-much-property-worth-module";
import NewsLetterForm from "../Components/CarrierLanding/LatestNews/LatestNews"
import ImageTransform from "../Components/common/ggfx-client/module/components/image-transform";
import { AltSiteName, NewsBaseURL } from "./utils";
import parse from 'html-react-parser';
import { useMatch } from "@reach/router"
const postsPerPage = 9;
let arrayForHoldingPosts = [];

const NewsListing = () => {    
    const ifnewsfilter = useMatch(NewsBaseURL+"/:item/");
    const [newsCat, setnewsCat] = useState("");
    const [newssearch, setNewssearch] = useState("");

    const Handleclick = (value) => {
        //console.log('aaaaaaaa 2', value)
        setnewsCat(value)
    }
    const Handlesearch = (value) => {
        setNewssearch(value)
    }

    useEffect(() => {
        if(ifnewsfilter) {
            setnewsCat (ifnewsfilter.item);
        }
    });
    const { loading: loading, error: error, data: data } = newssearch?SearchNews(0, 1, newssearch):FeaturedNews(0, 1);
    const { loading: all_loading, error: all_error, data: all_data } = newssearch?SearchNews(1, -1, newssearch):AllNews(1, -1);
    const [postsToShow, setPostsToShow] = useState([]);
    const [next, setNext] = useState(9);

    useEffect(() => {
        arrayForHoldingPosts = [];
      }, []);

      useEffect(() => {
        arrayForHoldingPosts = [];
      }, [newsCat]);

      useEffect(() => {
        arrayForHoldingPosts = [];
      }, [newssearch]);

    const loopWithSlice = (start, end) => {
        const slicedPosts = all_data.newsBlogs.slice(start, end);
        arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts];
        setPostsToShow(arrayForHoldingPosts);
      };
    
      const handleShowMorePosts = () => {
        loopWithSlice(next, next + postsPerPage);
        setNext(next + postsPerPage);
      };
      function removeTags(str) {
        if ((str===null) || (str===''))
        return false;
        else
        str = str.toString();
        return str.replace( /(<([^>]+)>)/ig, '');
     }
      useEffect(() => {
        all_data && loopWithSlice(0, postsPerPage);
      }, [all_data]);
      
      const GetCategory = (value) =>{
        setnewsCat(value)
      }
    
    var no_news= false;
    if(data && data.newsBlogs.length === 0) {
        no_news = true;
    }
    const ImageRenderBIG = ({ image_url, item, processedImages }) => {
        return (
            <ImageTransform imagesources={item.Tile_Image.url} renderer="srcSet" imagename="news-blogs.Tile_Image.featuredtile" attr={{ alt: item.Tile_Image.alternativeText?item.Tile_Image.alternativeText:item.Title+AltSiteName}} imagetransformresult={processedImages} id={item.id}/>  
        )
      }
      const ImageRenderList = ({ image_url, item, processedImages }) => {
        return (
            <ImageTransform imagesources={item.Tile_Image.url} renderer="srcSet" imagename="news-blogs.Tile_Image.commontile" attr={{ alt: item.Tile_Image.alternativeText?item.Tile_Image.alternativeText:item.Title+AltSiteName}} imagetransformresult={processedImages} id={item.id}/>  
        )
      }

    return (
        <React.Fragment>
            <div className="news-listing">
            <section className="property-transaction">
                <Container>
                    <Row>
                        <Col>
                            
                            <div className="property-wrapper">
                                <NewsCategory GetCategory={GetCategory}  Handleclick={Handleclick} Handlesearch={Handlesearch}/>
                                {no_news === true &&
                                <p className="text-center">Ther is no news/blogs listed under this category</p>
                                }
                                {data && data.newsBlogs.length > 0 && data.newsBlogs.map((item, index) => {
                                let processedImages = JSON.stringify({});
                                if (item?.imagetransforms?.Tile_Image_Transforms) {
                                processedImages = item.imagetransforms.Tile_Image_Transforms;
                                }	
                                    
                                return<>
                                <div className="tab-content">
                                    <div className="tab-pane">
                                        <div className="tab-wrapper">
                                            <div className="tab-img img-zoom featured-news-tile">
                                                
                                                <Link to={`${NewsBaseURL}/${item.Select_News_Category.URL}/${item.URL}/`} className="img-link">
                                                {/* <ImageTransform imagesources={item.Tile_Image.url} renderer="srcSet" imagename="news-blogs.Tile_Image.featuredtile" attr={{ alt: item.Tile_Image.alternativeText?item.Tile_Image.alternativeText:item.Title+AltSiteName}} imagetransformresult={processedImages} id={item.id}/>              */}
                                                <ImageRenderBIG processedImages={processedImages} image_url={item.Tile_Image.url} item={item} />

                                                {/* <img src={item.Tile_Image.url} alt={item.Tile_Image.alternativeText?item.Tile_Image.alternativeText:item.Title+AltSiteName}/> */}
                                                </Link>                                         
                                                
                                            </div>
                                            <div className="tab-info">
                                                
                                                    {/* <span className="date">
                                                        <DateFormat date={item.News_Published_Date}/>
                                                    </span> */}
                                                    <Link to={`${NewsBaseURL}/${item.Select_News_Category.URL}/${item.URL}/`} className="img-link">
                                                    <h2>{item.Title}</h2>
                                                    </Link>
                                                    <p>{parse(removeTags(item.Short_Intro.substr(0, 190)+'...'))}</p>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </>
                                })}
                                <Link to="#" className="searching d-none">
                                    <i className="search-icon">
                                    </i>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="landing-details">
                
                    <Container>
                        <Row>
                            <Col>
                                <div className="property-details">
                                    {postsToShow && postsToShow.length > 0 && postsToShow.map((item, index) => {
                                    let processedImages = JSON.stringify({});
                                    if (item?.imagetransforms?.Tile_Image_Transforms) {
                                    processedImages = item.imagetransforms.Tile_Image_Transforms;
                                    }	
                                    return<>
                                                                      
                                    <div className="property-wrapper">
                                        <div className="property-img img-zoom list-news-tile">
                                            <Link to={`${NewsBaseURL}/${item.Select_News_Category.URL}/${item.URL}/`} className="img-link">
                                            {/* <ImageTransform imagesources={item.Tile_Image.url} renderer="srcSet" imagename="news-blogs.Tile_Image.commontile" attr={{ alt: item.Tile_Image.alternativeText?item.Tile_Image.alternativeText:item.Title+AltSiteName}} imagetransformresult={processedImages} id={item.id}/> 
                                                                                                  */}
{/* <img src={item.Tile_Image.url} alt={item.Tile_Image.alternativeText?item.Tile_Image.alternativeText:item.Title+AltSiteName}/>                                                                                                   */}
                                            <ImageRenderList processedImages={processedImages} image_url={item.Tile_Image.url} item={item} />

                                            </Link>
                                        </div>
                                        
                                        <div className="property-info">
                                            {/* <span className="date">
                                                <DateFormat date={item.News_Published_Date}/>
                                            </span> */}
                                            <h2>
                                            <Link to={`${NewsBaseURL}/${item.Select_News_Category.URL}/${item.URL}/`}>
                                                {item.Title}
                                            </Link>
                                            </h2>
                                        </div>
                                    </div> 
                                    {index == 5 &&
                                    <div className="property-wrapper news-how-much-module">
                                        <PropertyWorthModule/>
                                    </div>
                                    }                            
                                    </>
                                    })}
                                   
                                </div>
                                {postsToShow && postsToShow.length > 8 && postsToShow.length >= next &&
                                    <a onClick={handleShowMorePosts} className="btn">
                                        Load More News
                                    </a>
                                } 
                            </Col>
                        </Row>
                    </Container>
                
            </section>
            <NewsLetterForm/>
            </div>
        </React.Fragment>
    )
}
export default NewsListing
