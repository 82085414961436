import React, { useState } from "react";
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import { NewsBaseURL } from "./utils";
import { NewsCategories } from "../queries/common_use_query";
import { useMatch } from "@reach/router"
import { Form } from "react-bootstrap"
import {SearchIcon} from "../Components/icons"
import $ from "jquery"

const NewsCategory = (props) => {
    const { loading: loading, error: error, data: data } = NewsCategories();
    const catmatch = useMatch(NewsBaseURL+"/:item/");
    const matchcat = catmatch && catmatch.item;
    const [currentcat, setCurrentcat] = useState("");
    const [searchtitle, setSearchtitle] = useState("");

    const Handlecat = (value) => {
        props.Handleclick(value)
        setCurrentcat(value)
    }
    //console.log('aaaaaa 1',currentcat)
   const handleNewsFilter = (e) => {
       var newskey = $('#searchnews').val();
       props.Handlesearch(newskey)
       setSearchtitle(newskey)
   }
  
    return (
        <React.Fragment>
            <div className="news-nav-wrapper">
            <nav class="nav nav-tabs" role="tablist">
                {/* <a id="uncontrolled-tab-example-tab-home" href="javascript:void(0)" role="tab" data-rb-event-key="home" onClick={()=>{setCurrentcat("");Handlecat("");}} aria-controls="uncontrolled-tab-example-tabpane-home" aria-selected="true" className={currentcat?"nav-item nav-link":"nav-item nav-link active"}>All News</a>
                {data && data.newsCategories.length > 0 && data.newsCategories.map((item, index) => {
                return<>
                <a href="javascript:void(0)" id="uncontrolled-tab-example-tab-profile" onClick={()=>Handlecat(item.URL)} role="tab" data-rb-event-key="profile" aria-controls="uncontrolled-tab-example-tabpane-profile" aria-selected="false" className={currentcat === item.URL?"nav-item nav-link active":"nav-item nav-link"}>{item.Name}</a>
                </>
                })}
                <a href="javascript:void(0);" className="searching">
                <i className="search-icon">
                </i>               
            </a> */}
            <Form className="news-filter">
                    <Form.Control  id="searchnews" onKeyUp={(e)=>handleNewsFilter()}  placeholder="Search by topic, service etc" />
                </Form>  
                <a href="javascript:void(0);" className="searching">
                    <SearchIcon/>                
                </a>
            </nav>
            </div>
            {/* <a href="javascript:void(0);" className="searching">
                <i className="search-icon">
                </i>
                <Form className="news-filter">
                    <Form.Control  id="searchnews" onKeyUp={(e)=>handleNewsFilter()}  placeholder="Search..." />
                </Form>                 
            </a> */}
            

        </React.Fragment>
    )
}
export default NewsCategory
