import React, { useState, useEffect } from "react";
import { Link } from "gatsby"
import Select from "react-dropdown-select";
import { BranchFilter, DepartmentFilter } from "../../queries/common_use_query";
import $ from "jquery"
const TeamFilter = (props) => {
    useEffect(() => {
        $('head').append('<meta name="viewport" content="width=device-width, initial-scale=1.0, shrink-to-fit=no, maximum-scale=1.0, user-scalable=0">');
    })
    const {loading:loading, error:error, data:data} = BranchFilter();
    const {loading:dept_loading, error:dept_error, data:dept_data} = DepartmentFilter();   
    
    var branch_list = [{value: "ALL", label: "All Branches"}];
    data && data.ourOffices.map(office=> branch_list.push({value: office.URL, label: office.Name}))

    var dept_list = [{value: "", label: "All Departments"}];
    dept_data && dept_data.departments.map(dept=> dept_list.push({value: dept.URL, label: dept.Name}))
    
    return (
        <React.Fragment>
            <div className="filter-branch">
            <Select placeholder="All branches" searchable={false}                
                options={branch_list}
                onChange={(e) => {console.log("Teamfilter 2", e); props.handleChange(e[0].value)}}
            />
            </div>
            <div className="filter-dept d-none">
            <Select className="departments" placeholder="all departments" searchable={false}
                options={dept_list}
                onChange={(e) => {console.log("Teamfilter 3", e); props.handleChangeDept(e[0].value)}}

            />
            </div>
        </React.Fragment>
    )
}
export default TeamFilter
