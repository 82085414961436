import React, { useState } from "react";
import { Link } from "gatsby"

import { Container, Row, Col, Form, Button } from "react-bootstrap"
import ScrollAnimation from 'react-animate-on-scroll';

import "./LatestNews.scss";
import NewsLetterPageForm from '../../forms/newsletter-page-form';
const NewsLetterForm = () => {

    return (
        <React.Fragment>
            <section className="latest-news page-newsletter-form">
                
                    <Container>

                        <Row>
                            <Col>
                                <div className="news-info">
                                    <h2>Get the latest news first</h2>
                                    <span className="sm-text">Stay up to date with latest news and featured properties</span>
                                    <NewsLetterPageForm />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                
            </section>
        </React.Fragment>

    )
}
export default NewsLetterForm
