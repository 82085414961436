import React, { useState, useEffect } from "react";
import Helmet from "react-helmet"
import LoadExternalScript from "../Components/utils/load-external-script";
import TrustpilotReviews from "../Components/trustpilot"
import {
    Button, Navbar, Nav, Form, Container, FormControl, Row, Col, dark,
} from "react-bootstrap";
const TrustPilotList = (props) => {
    return (
        <React.Fragment>
            <section className="reviews-grid-list">
                <Container>
                    <Row>
                        <Col xl={12}>
                            <TrustpilotReviews
                                language="en"
                                culture="US"
                                theme="light"
                                width="100%"
                                height="800px"
                                template="539adbd6dec7e10e686debee"
                                business="5ca72f2628a1c800015b7cb2"
                                username="balgoresproperty"
                                data-text-color="#e0e0e0"
                                stars="4,5"
                            />
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    )
}
export default TrustPilotList;
