import React, { useState } from "react";
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import ScrollAnimation from 'react-animate-on-scroll';
import parse from 'html-react-parser';
import "./MortageServices.scss"
import GetCTA from "../../getcta";
import { getSrc } from "gatsby-plugin-image"
import { AltSiteName } from "../../utils";
import TileAccordion from "../../CarrierLanding/Work/TileAccordion";
import getVideoId from "get-video-id";
import PlayVideo from "../../Play/PlayVideo";
import TrustPilotBadge from "../../trust-pilot-badge-blue";
// Header component

const RightTile = (props) => {
    const [isPlay, setPlay] = useState(false);
    const videoid = props.video && getVideoId(props.video);
    var pattern = /^((http|https|ftp):\/\/)/;
    var target= ""
    if(pattern.test(props.customlink)) {
        target = "_blank";
    } 
    return (
        <React.Fragment>
            <section id={`tile-bg-${props.bgcolor?props.bgcolor:"default"}`} className={`mortage-services tileblock ${props.style} tile-block-right`}>
                <Container>
                    <Row className={props.accordion?"flex-lg-row-reverse align-items-center":"flex-lg-row-reverse align-items-center"}>
                        <Col lg="6">
                            <div className="mlr-minus">
                            <picture className="img-zoom">                                    
                                    <img src={getSrc(props.image.url_sharp)}  alt={props.image.alternativeText?props.image.alternativeText:props.title+AltSiteName}/>
                            </picture>
                            {props.video?
                            <button
                            type="button"
                            className="btn-play"
                            onClick={(e) => {
                            setPlay(true);}}>
                            <i className="icon-play"></i>
                            </button>
                            :""}
                            </div>
                          </Col>
                        <Col lg="6">
                            <div className="services-content">
                                {props.title &&
                                    <span className="small-title">{props.title}</span>
                                }
                                {parse(props.content)}
                                {props.id === "61544e1ba95d1d40b9a8a0d0" &&                                    
                                    <TrustPilotBadge/>
                                }
                                {props.customlink && target &&
                                    <a href={props.customlink} target="_blank" className="link-text">{props.label}</a>
                                }
                                {props.customlink && !target ?
                                <Link to={props.customlink} className="link-text">{props.label}</Link>
                                :
                                <GetCTA Label={props.label} link={props.link && props.link.Primary_URL} class="link-text"/>
                                }
                                {props.accordion?
                                <TileAccordion accordion={props.accordion}/>
                                :""}
                                
                            </div>
                            
                        </Col>
                    </Row>
                </Container>
            </section>
    {videoid && props.video &&
      <PlayVideo
        isOpen={isPlay}
        isCloseFunction={setPlay}
        videoId={videoid.id}
        isAutoPlay={1}
      />
      }
        </React.Fragment>
    )
}
export default RightTile
