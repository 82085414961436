import React, { useState } from "react";
import { Link } from "gatsby";
import { Container, Row, Col, Form } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import "./AboutBoost.scss";
import parse from 'html-react-parser';

const AboutBoost = (props) => {
  return (
    <React.Fragment>
      <section className="about-boost two-box-content">
        
          <Container>
            <Row>
              <Col lg="6">
                <div class="highlight-text">
                  {parse(props.leftcontent)}
                </div>
              </Col>
              <Col lg="6">
                <div className="about-boost-text">
                  {parse(props.rightcontent)}
                </div>
              </Col>
            </Row>
          </Container>
        
        
      </section>
      
    </React.Fragment>
  );
};
export default AboutBoost;
