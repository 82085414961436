import { Link } from "gatsby";
import React, { useEffect, useState, useRef } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import {
  Button, Navbar, Nav, Form, Container, FormControl, Row, Col, dark,
} from "react-bootstrap";
import "./Tetstimonials.scss";

import customerImg from "../../../images/customer.jpg";
import customerImg2 from "../../../images/customer2.jpg";
import feefo from "../../../images/feefo.svg";
import TrustPilotSlider from "../../trust-pilot-slider";
import TrustPilotLogo from "../../trust-pilot-small-logo";
const Tetstimonials = (props) => {

  return (
    <React.Fragment>
      <section className="testimonial-review">
        <Container>
          <Row>
            

              <Col xl={12}>
                <div className="block-heading">
                  <h2>What our customers say</h2>
                  <p>Don't take our word for it - find out what our customers have to say about their experiences with Balgores.</p>
                </div>
              </Col>
            
          </Row>


          <div className="review-item">
            
              <Row>
                <TrustPilotSlider/>
                {/* <Col md={6} lg={4} className="pr">

                  <div className="review-content">
                    <div className="reviewer-block">
                      <div className="reviewer-image">
                        <img src={customerImg} alt="" />
                      </div>
                      <div className="reviewer-detail">
                        <span className="name">Emilia Young</span>
                        <span className="review-date">3 days ago</span>
                      </div>
                    </div>
                    <div className="review-feedback">
                      <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make...</p>
                      <ul className="ratings">
                        <li>
                          <i className="icon-star-dark"></i>
                        </li>
                        <li>
                          <i className="icon-star-dark"></i>
                        </li>
                        <li>
                          <i className="icon-star-dark"></i>
                        </li>
                        <li>
                          <i className="icon-star-dark"></i>
                        </li>
                        <li>
                          <i className="icon-star-dark"></i>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col md={6} lg={4} className="d-none d-md-block pl">

                  <div className="review-content">
                    <div className="reviewer-block">
                      <div className="reviewer-image">
                        <img src={customerImg2} alt="" />
                      </div>
                      <div className="reviewer-detail">
                        <span className="name">Emilia Young</span>
                        <span className="review-date">3 days ago</span>
                      </div>
                    </div>
                    <div className="review-feedback">
                      <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make...</p>
                      <ul className="ratings">
                        <li>
                          <i className="icon-star-dark"></i>
                        </li>
                        <li>
                          <i className="icon-star-dark"></i>
                        </li>
                        <li>
                          <i className="icon-star-dark"></i>
                        </li>
                        <li>
                          <i className="icon-star-dark"></i>
                        </li>
                        <li>
                          <i className="icon-star-dark"></i>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col md={6} lg={4} className="d-none d-lg-block pl">
                  <div className="review-content">
                    <div className="reviewer-block">
                      <div className="reviewer-image">
                        <img src={customerImg2} alt="" />
                      </div>
                      <div className="reviewer-detail">
                        <span className="name">Emilia Young</span>
                        <span className="review-date">3 days ago</span>
                      </div>
                    </div>
                    <div className="review-feedback">
                      <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make...</p>
                      <ul className="ratings">
                        <li>
                          <i className="icon-star-dark"></i>
                        </li>
                        <li>
                          <i className="icon-star-dark"></i>
                        </li>
                        <li>
                          <i className="icon-star-dark"></i>
                        </li>
                        <li>
                          <i className="icon-star-dark"></i>
                        </li>
                        <li>
                          <i className="icon-star-dark"></i>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col> */}
              </Row>
            
          </div>
          <Row>
            <Col xl={12} className="d-md-flex align-items-center">
              <div className="review-rates">
                <TrustPilotLogo height="150px" width="100%"/>
                {/* <img src={feefo} alt="" />
                <p>Rated <span>4.5/5</span> from 78 Reviews</p> */}
              </div>
              <div className="btn-row">
                <Link to="/about/customer-reviews" className="btn btn-primary see-more">See more of our reviews</Link>
              </div>
            </Col>
            <Col xl={12} className="d-none ps-md-0 pe-md-0">
              <Link to="#" className="btn btn-enquiry">Make an enquiry</Link>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Tetstimonials;
