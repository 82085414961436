import React, { useState, useEffect } from "react";
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import ScrollAnimation from 'react-animate-on-scroll';
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";
import { AltSiteName, TeamBaseURL, ContactFormURL } from "../../utils";
import "./Team.scss"
const TeamListFilter = (props) => {  
  const ImageRenderList = ({ image_url, item, processedImages }) => {
    return (
      <ImageTransform imagesources={item.Staff_Image.url} renderer="srcSet" imagename="teams.Staff_Image.commontile" attr={{ alt: item.Staff_Image.alternativeText?item.Staff_Image.alternativeText:item.Name+' - '+item.Designation+AltSiteName}} imagetransformresult={processedImages} id={item.id}/>                           

    )
  }
  return (
    <React.Fragment>
      
            <div className="team-slider row">
            {props.teams && props.teams.length > 0 && props.teams.filter(list => list.Select_Office.map(c => c.URL).includes(props.Teamfilter)).map((item, index) => {
                let processedImages = JSON.stringify({});
                if (item?.imagetransforms?.Staff_Image_Transforms) {
                processedImages = item.imagetransforms.Staff_Image_Transforms;
                }
                return<>
                <div className="team-item col-md-6 col-lg-3">
                  {/* <Link to={`${TeamBaseURL}/${item.URL}`} className="img-zoom"> */}
                  {/* <ImageRenderList processedImages={processedImages} image_url={item.Staff_Image.url} item={item} /> */}
                  <img src={item.Staff_Image.url} alt={item.Staff_Image && item.Staff_Image.alternativeText?item.Staff_Image.alternativeText:item.Name+' - '+item.Designation+AltSiteName} />
                  {/* </Link> */}
                  <div className="team-block">
                    <span className="date-text">{item.Name}</span>
                    <span className="profile">{item.Designation}</span>
                    <Link to={`${ContactFormURL}?tid=${item.id}`} className="mail">Send an email</Link>
                  </div>
                </div>
                </>
            })}
            </div>       
    </React.Fragment>
  )
}
export default TeamListFilter
