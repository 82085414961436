import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { Container, Row, Col, Table } from 'react-bootstrap';
import PlayVideo from "../../../Components/Play/PlayVideo";
import ScrollAnimation from 'react-animate-on-scroll';
import parse from 'html-react-parser';
import { getSrc } from "gatsby-plugin-image"
import getVideoId from 'get-video-id';

import './About.scss'
// markup
const About = (props) => {
    const [isPlay, setPlay] = useState(false);
    const [VideoID, setVideoID] = useState(false);
    const videoid = props.videourl && getVideoId(props.videourl);

    return (
        <React.Fragment>
            <section className="about-sales boost-top">
                
                    <Container>
                        <Row>
                            <Col className="d-lg-flex justify-content-between align-items-start">
                                <div className="about-details">
                                    
                                        {parse(props.leftcontent)}
                                        <div className="details-wrapper d-md-flex justify-content-between">
                                            {props.counter && props.counter.map((count) => {
                                            return<>
                                            <div className="sm-details">
                                                <span className="value">{count.Value}<span className="sign">{count.Value_Type}</span></span>
                                                <div className="content">
                                                    {parse(count.Value_Content)}                                                   
                                                </div>
                                            </div>
                                            </>
                                            })}
                                        </div>
                                    
                                </div>
                                <div className="about-img ">
                                    
                                        <picture>
                                            <img src={getSrc(props.rightimage.url_sharp) } alt={props.rightimage.alternativeText?props.rightimage.alternativeText:""} />                                            
                                        </picture>
                                        {/* <div className="about-btn">
                                            <Link to="#" className="btn btn-primary">
                                                chat with us
                                            </Link>
                                        </div> */}
                                        {props.videourl?
                                        <button
                                            type="button"
                                            className="btn-play"
                                            onClick={(e) => { setPlay(true); setVideoID(videoid.id);}}
                                        >
                                            <i className="icon-play"></i>
                                        </button>
                                        :""}
                                    
                                </div>
                            </Col>
                        </Row>
                    </Container>
                
            </section>
            {VideoID &&
            <PlayVideo
                isOpen={isPlay}
                isCloseFunction={setPlay}
                videoId={VideoID}
                isAutoPlay={1}
            />
            }
        </React.Fragment >
    )
}
export default About
