import React, { useState } from "react";
import { Link } from "gatsby";
import Select from "react-dropdown-select";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import "./ValuationInfo.scss";
import BookValuationForm from '../../forms/book-a-valuation';
const BookaValuationForm = (props) => {
  const Departments = [
    { value: "Option", label: "Option" },
    { value: "Option1", label: "Option1" },
    { value: "Option2", label: "Option2" },
    { value: "Mortgage type", label: "Mortgage type" },
  ];
  const Branch = [
    { value: "Option", label: "Option" },
    { value: "Option1", label: "Option1" },
    { value: "Option2", label: "Option2" },
    { value: "Select branch", label: "Select branch" },
  ];
  return (
    <React.Fragment>
      <section className="valuation-info">
        <Container>
          <Row>
            <Col>
              
              

                <div className="valuation-box book">
                  <Link className="icons">
                    <i className="home">
                    </i>
                  </Link>
                  <h3>Book a Valuation</h3>
                  <p>Book a face-to-face valuation with one of our experts on your preferred date and time.</p>
                  <BookValuationForm postcode={props.postcode} />
                  {/* <Form>
                    {['radio'].map((type) => (
                      <div key={`inline-${type}`} className=" form-wrapper">
                        <Form.Check className="d-none d-md-inline-block"
                          inline
                          label="Sale Value"
                          name="group1"
                          type={type}
                          id={`inline-${type}-1`}
                        />

                        <Form.Check className="d-none d-md-inline-block"
                          inline
                          label="Rental Value"
                          name="group1"
                          type={type}
                          id={`inline-${type}-2`}
                        />

                        <Form.Check className="d-md-none"
                          inline
                          label="Sale"
                          name="group1"
                          type={type}
                          id={`inline-${type}-1`}
                        />

                        <Form.Check className="d-md-none"
                          inline
                          label="Rental"
                          name="group1"
                          type={type}
                          id={`inline-${type}-2`}
                        />

                        <Form.Check
                          inline
                          label="Both"
                          name="group1"
                          type={type}
                          id={`inline-${type}-2`}
                        />

                      </div>
                    ))}


                    <Form.Group className="mb-3 address d-none" controlId="formBasicEmail">
                      <Form.Control type="text" placeholder="Start typing your address..." />
                      <button type="button" className="btn-circle">
                        <i className="icon-arrow"></i>
                      </button>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Control type="text" placeholder="Your name" />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Control type="mail" placeholder="Your email" />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Control type="tel" placeholder="Your phone number" />
                    </Form.Group>

                    <Form.Group className="mb-3 react-menu d-none d-lg-block" controlId="formBasicEmail">
                      <Select className="departments form-control" placeholder="Mortgage type" searchable="false"
                        options={Departments}
                        values={[]}
                      />                  </Form.Group>

                    <Form.Group className="mb-3 react-menu d-none d-lg-block" controlId="formBasicEmail">
                      <Select className="branch form-control" placeholder="Select branch" searchable="false"
                        options={Branch}
                        values={[]}
                      />                  </Form.Group>


                    <div className="timing">
                      <Form.Group className="mb-3 " controlId="formBasicEmail">
                        <Form.Control className="clock" type="text" placeholder="Preferred time" />
                        <i className="icon-clock">
                        </i>
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control className="date" type="text" placeholder="Preferred date" />
                        <i className="icon-calendar">
                        </i>
                      </Form.Group>
                    </div>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Control type="text" placeholder="Enter your postcode" />
                    </Form.Group>

                    <Form.Group controlId="exampleForm.ControlTextarea1" >
                      <Form.Control placeholder="Your message" as="textarea" rows={3} className="area" />
                    </Form.Group>

                    <Form.Group className="mb-3 sign">
                      <Form.Check type="checkbox" label="Get emails with the latest news and
                     information on the local property market,
                     our products and services. You can unsubscribe at any time."/>
                    </Form.Group>

                    {['checkbox'].map((type) => (
                      <div key={`inline-${type}`} className="mb-3 data">
                        <Form.Check
                          inline
                          name="group1"
                          type={type}
                          id={`inline-${type}-2`}
                        />
                        <span className="form-check-label">
                          I have read and agree to the <Link to="#"> Terms and
                            Conditions, </Link> <Link to="#"> Privacy Policy </Link> and <Link to="#"> Cookies Policy. </Link>
                        </span>
                      </div>
                    ))}
                    <Button type="submit" className="btn btn-primary">
                      Submit
                    </Button>
                  </Form> */}
                </div>
              
              <div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};
export default BookaValuationForm;
