import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { Container, Row, Col, Table } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import './Achieve.scss'
import parse from 'html-react-parser';
import DateFormat from "../../format-auction-date";

// markup

const Achieve = (props) => {
    const handleTime = (dataD) => {
        let data= new Date(dataD)
        let hrs = data.getHours()
        let mins = data.getMinutes()
        if(hrs<=9)
           hrs = '0' + hrs
        if(mins<10)
          mins = '0' + mins
        const postTime= hrs + ':' + mins
        return postTime
      }
    return (
        <React.Fragment>
            <section className="achieve auctions-achieve">
                
                    <Container>
                        <Row>
                            <Col className="d-lg-flex justify-content-between">
                                <div className="achieve-details">
                                    {parse(props.leftcontent)}                                    
                                </div>
                                <div className="auctions-wrapper">
                                    <div className="next-auction">
                                        <h2>Next Auction</h2>
                                        <ul className="times d-flex justify-content-between">                                            
                                            <li><DateFormat date={props.nextauctiondate}/></li>
                                            <li className="d-flex align-items-start"><i className="icon-clock"></i>
                                            {handleTime(props.nextauctiondate)}
                                            </li>
                                        </ul>
                                        <a target="_blank" className="sm-text" href={props.onlineautionlink}>Online Auction</a>
                                        <a target="_blank" className="btn btn-primary" href={props.lotslink}>View lots</a>
                                    </div>
                                    <div className="bottom-content">
                                        <h2>Upcoming Auctions</h2>
                                        <ul className="days d-flex justify-content-between">
                                            {props.upcomingauctions && props.upcomingauctions.map((auction) => {
                                                return<>
                                                <li>
                                                <DateFormat weekday="short" month="short" date={auction.Auction_Date}/> {handleTime(auction.Auction_Date)}
                                                </li>
                                                <li>
                                                <a href={auction.Auction_Catalog_Link} target="_blank">View Catalog</a>
                                                </li>
                                                </>
                                            })}
                                            
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                
            </section>
        </React.Fragment >
    )
}
export default Achieve

