import React, { useState } from "react";
import { Link } from "gatsby";
import Select from "react-dropdown-select";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import "./ValuationInfo.scss";
import parse from 'html-react-parser';
import GetCTA from "../../getcta";
const ValuationInfo = (props) => {

  return (
    <React.Fragment>
      <section className="valuation-info">
        <Container>
          <Row>
            <Col>
              <div className="info">
                <h1>{props.title}</h1>
                {parse(props.description)}
                
                  <div className="box-wrapper">
                    {props.boxes && props.boxes.map((box) => {
                      return <>
                        <div className="valuation-box">
                          <Link to="#" className="icons">
                            <i className={box.Valuation_Box_Icon_Class}>
                            </i>
                          </Link>
                          <h3>{box.Valuation_Box_Title}</h3>
                          {parse(box.Valuation_Box_Description)}
                          <GetCTA class="btn btn-primary" Label={box.Valuation_Box_CTA_Label} link={box.Valuation_Box_CTA_Link && box.Valuation_Box_CTA_Link.Primary_URL} />                                                    
                        </div>
                      </>
                    })}

                  </div>
                

              </div>

              <div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};
export default ValuationInfo;
