import React, { useState } from "react";
import { Link } from "gatsby";
import { Container, Row, Col, Form } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import PlayVideo from "../../../Components/Play/PlayVideo";
import "./AboutBoost.scss";
import { useStaticQuery, graphql } from "gatsby"
import parse from 'html-react-parser';
import { AltSiteName } from "../../utils";
import { getSrc } from "gatsby-plugin-image"
import getVideoId from 'get-video-id';

const AboutBoost = () => {
const [isPlay, setPlay] = useState(false);
const [VideoID, setVideoID] = useState(false);

const data = useStaticQuery(graphql`
    query  {  
      glstrapi {
        globalConfig {
          Balgores_Boost {
            Module_Title
            Module_Video_URL
            Module_Link_Label
            Module_Link
            Module_Content
            Module_Image {
              url
              alternativeText
              url_sharp {
                childImageSharp {
                  gatsbyImageData(formats: AUTO
                    width: 675
                    quality: 80
                    layout: FIXED
                    transformOptions: {cropFocus: CENTER, fit: COVER}
                  )    
                }
              }
            }
          }
        }
      }
    }
  `)
  var boost_module = data.glstrapi.globalConfig.Balgores_Boost;
  let moduleimage = boost_module.Module_Image.url_sharp ? getSrc(boost_module.Module_Image.url_sharp) : ''
  const videoid = boost_module.Module_Video_URL && getVideoId(boost_module.Module_Video_URL);

  return (
    <React.Fragment>
      
      <section className="about-boost tile-boost-module">        
        <div className="about-box">
          <div className="about-block-img">
            <picture className="img-zoom">              
              <img src={moduleimage} alt={boost_module.Module_Image.alternativeText?boost_module.Module_Image.alternativeText:boost_module.Module_Title+AltSiteName}/>
              {boost_module.Module_Video_URL?
              <button
                type="button"
                className="btn-play"
                onClick={(e) => { setPlay(true); setVideoID(videoid.id);}}
              >
                <i className="icon-play"></i>
              </button>
              :""}
            </picture>
          </div>
          <Container>
            <Row>
              <Col md="12" className="ms-lg-auto">
                <div className="about-boost-content">
                  <span className="small-title">{boost_module.Module_Title}</span>                 
                  {parse(boost_module.Module_Content)}                                    
                  <Link to={boost_module.Module_Link} className="link-text">
                    {boost_module.Module_Link_Label}
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      
      {VideoID &&
      <PlayVideo
        isOpen={isPlay}
        isCloseFunction={setPlay}
        videoId={VideoID}
        isAutoPlay={1}
      />
      }
    </React.Fragment>
  );
};
export default AboutBoost;
