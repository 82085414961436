import React, { useState, useEffect } from "react";
import { Link } from "gatsby"
import Select from "react-dropdown-select";
import ScrollAnimation from 'react-animate-on-scroll';
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import "./LandingBanner.scss"
import parse from 'html-react-parser';
import GetCTA from "../getcta";
import Trustpilot from '../../images/trustpilot.svg'
import { getSrc } from "gatsby-plugin-image"
import { AltSiteName } from "../utils";
import $ from 'jquery';
import TeamFilter from "./TeamFilter";
import BreadCrumbs from '../PropertyDetails/BackButton/BreadCrumbs'
import { useMatch } from "@reach/router"
import TrustPilotBadge from "../trust-pilot-badge"
import { navigate } from "@reach/router";
import SearchInputBox from '../predictive-search/search-areas';

const Intro = (props) => {
    var areabreadcrumbs = ''
    areabreadcrumbs = useMatch("/branches/:item") 
    if(areabreadcrumbs!=null && areabreadcrumbs.item != 'area-guides') {
        areabreadcrumbs = areabreadcrumbs
    } else {
        areabreadcrumbs= ''
    }
    const Brentwood = [
        { value: "Basildon", label: "Basildon" },
        { value: "Brentwood", label: "Brentwood" },
        { value: "Chelmsford", label: "Chelmsford" },
        { value: "Chelmsford", label: "Chelmsford" },
        { value: "Dagenham", label: "Dagenham" },
        { value: "Gravesend", label: "Gravesend" },
        { value: "Hornchurch Sales", label: "Hornchurch Sales" },
        { value: "Hornchurch Lettings", label: "Hornchurch Lettings" },
    ];

    const Departments = [
        { value: "Option", label: "Option" },
        { value: "Option1", label: "Option1" },
        { value: "Option2", label: "Option2" },
        { value: "Option3", label: "Option3" },
    ];
    var introbanner = props.bannerimage && getSrc(props.bannerimage.url_sharp)
    var introtile   = props.image && getSrc(props.image.url_sharp)
    const [scroll, setScroll] = useState(false);

    useEffect(() => {
        $('#buy-checkbox').attr('checked',true);

        window.addEventListener("scroll", () => {
          setScroll(window.scrollY > 1);
        });
        // $(".search_btn").click(function() {
        //     var pcode = $("input[name=search-postcode]").val()
        //     if(pcode) {
        //         navigate("/property-valuation/free-valuation?postcode="+pcode);
        //     } else {
        //         navigate("/property-valuation/free-valuation");
        //     }
        // });
        let url = "/";
        $(".search_btn").click(function() {
            $(window).scrollTop(0);
            var ptype = $("input[name=search-property]:checked").val()
            var searcValue = $(".banner-content .searchVal").val().split(', ').join('-').replace(/ /g, "-").replace(/'/g, "").toLowerCase();
            if(ptype === 'buy') {
                if(searcValue !== "") {
                    url = searcValue+"/"
                } else {
                    url = "essex/"
                }
                navigate("/properties-for-sale/"+url);
            }
            if(ptype === 'rent') {
              if(searcValue !== "") {
                  url = searcValue+"/"
              } else {
                  url = "essex/"
              }
              navigate("/properties-to-rent/"+url);
          }
    
        });
      }, []);
      function gotohash(event) {
        var thishash = event;
        $('html, body').animate({
          scrollTop: $(thishash).offset().top - 70
        }, 1000);
      }
    return (
        <React.Fragment>
            <div id={introbanner?"with-intro-full-banner":""} className={introtile?"with-banner-tile":""}>
            <section className={scroll?"landing-banner introbanner":"landing-banner introbanner scroll-intro"}>
                    {introbanner?
                    <div className="intro-banner-img">
                        <img src={introbanner} alt={props.bannerimage.alternativeText?props.bannerimage.alternativeText:props.title+AltSiteName}/>
                    </div>
                    :""}
                    <Container>
                        <Row>
                            <Col lg={introtile?"7":""}>
                                <div className="banner-content">
                                    <BreadCrumbs style="banner-breadcrumbs" alias={areabreadcrumbs?'area-guides':""} menulabel={areabreadcrumbs && areabreadcrumbs.item?areabreadcrumbs.item.replace("-area-guides", ""):""}/>
                                    <span className="sm-text">{props.title}</span>
                                    {parse(props.content)}
                                    {props.ctas && props.ctas.length > 0 && props.breadcrumbtitle!= "Careers" &&
                                    
                                    <div className="btn-intro">
                                    {props.ctas && props.ctas.length > 0 && props.ctas.map((cta,index) => {
                                    var pattern = /^((http|https|ftp):\/\/)/;
                                    var target= ""
                                    if(pattern.test(cta.CTA_Custom_Link)) {
                                        target = "_blank";
                                    }   
                                    return<>
                                        {index === 0 &&
                                        <>
                                        {cta.CTA_Custom_Link && !target ?
                                        <Link to={cta.CTA_Custom_Link} className="btn btn-primary">{cta.CTA_Label}</Link>
                                        :
                                        <GetCTA Label={cta.CTA_Label} class="btn btn-primary" link={cta.CTA_Link && cta.CTA_Link.Primary_URL}/>
                                        }
                                        </>
                                        }
                                        {index === 1 &&
                                        <>
                                        {cta.CTA_Custom_Link && !target ?
                                        <Link to={cta.CTA_Custom_Link} className="btn btn-transparent">{cta.CTA_Label}</Link>
                                        :
                                        <GetCTA Label={cta.CTA_Label} class="btn btn-transparent" link={cta.CTA_Link && cta.CTA_Link.Primary_URL}/>
                                        }
                                        </>
                                        }
                                        {index === 0 &&
                                        <>
                                        {cta.CTA_Custom_Link && target &&
                                            <a href={cta.CTA_Custom_Link} target={target} className="btn btn-primary">{cta.CTA_Label}</a>
                                        }
                                        </>
                                        }
                                        {index === 1 &&
                                        <>
                                        {cta.CTA_Custom_Link && target &&
                                            <a href={cta.CTA_Custom_Link} target={target} className="btn btn-transparent">{cta.CTA_Label}</a>
                                        }
                                        </>
                                        }
                                    </>
                                    })}
                                    </div>
                                    
                                    }
                                    {/* ONLY CAREERS CTA */}
                                    {props.ctas && props.ctas.length > 0 && props.breadcrumbtitle === "Careers" &&
                                    
                                    <div className="btn-intro">
                                    {props.ctas && props.ctas.length > 0 && props.ctas.map((cta,index) => {
                                    return<>
                                        {index === 0 &&
                                        <>
                                        {cta.CTA_Custom_Link &&
                                         <a href="javascript:;"  onClick={() => gotohash(cta.CTA_Custom_Link)} className="btn btn-primary">{cta.CTA_Label}</a>                                        
                                        }
                                        </>
                                        }
                                        {index === 1 &&
                                        <>
                                        {cta.CTA_Custom_Link &&
                                        <a href="javascript:;"  onClick={() => gotohash(cta.CTA_Custom_Link)} className="btn btn-transparent">{cta.CTA_Label}</a>                                        
                                        }
                                        </>
                                    }
                                    </>
                                    })}
                                    </div>
                                                                        
                                    }
                                    {/* ONLY CAREERS CTA */}
                                    {props.showsearch ?
                                    <div className="banner">
                                    {/* <Form>
                                     <Form.Control name="search-postcode" type="text" placeholder="Search by postcode" />                                     
                                     <Button variant="primary" type="button" className="search_btn">Get a valuation</Button>
                                    </Form>       */}
                                    <Form className={`search-banner`} id ={`search-banner-form`}>
                                        {/* <Form.Control type="text" placeholder="Search by postcode or area" /> */}
                                        <SearchInputBox />
                                        <div className="custom-checkbox">
                                            <Form.Group controlId="buy-checkbox">
                                                <Form.Check type="radio" name="search-property" label="Buy" value="buy" />
                                            </Form.Group>
                                            <Form.Group  controlId="rent-checkbox">
                                                <Form.Check type="radio" name="search-property" label="Rent" value="rent" />
                                            </Form.Group>
                                        </div>
                                        <Button className="search_btn" variant="primary" type="button">Find a property</Button>
                                    </Form>
                                    </div>                      
                                    :""}
                                    {props.showreviews ?
                                    <div className="banner">
                                     <div className="star-rating">
                                     <TrustPilotBadge />
                                     {/* <img src={Trustpilot} alt="trustpilot"/> */}
                                    </div>
                                    </div>
                                    :""}
                                    {props.filters?
                                    <div className="react-selector">
                                    <TeamFilter handleChange={props.handleChange} handleChangeDept={props.handleChangeDept}/>
                                    </div>:""    
                                    }
                                </div>
                            </Col>
                            
                        </Row>
                    </Container>
                    {introtile?
                            <Col lg={5} className="intro-tile-col">
                            <div className="intro-tile-img">
                            <img src={introtile} alt={props.image.alternativeText?props.image.alternativeText:props.title+AltSiteName}/>
                            </div>
                            </Col>
                            :""}
            </section>
            </div>
        </React.Fragment>
    )
}
export default Intro
