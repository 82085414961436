import React, { useState } from "react";
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import ScrollAnimation from 'react-animate-on-scroll';
import parse from 'html-react-parser';
import GetStaff from "../../get-staff-strapi";
import "./About.scss"
// Header component

const About = (props) => {
    return (
        <React.Fragment>            
            <section className="about-section pb-0 pt-0" id={props.member?"short-intro-with-staff":""}>
                <Container>
                    <Row>
                        <Col lg={props.member?8:""}>
                            <div className="about-info">
                                
                                    <span className="small-title">{props.title?props.title:""}</span>
                                    {parse(props.content)}
                                
                            </div>
                        </Col>
                        {props.member?
                        <Col lg={4} md={6}>
                            <div className="right-block">
                            <GetStaff member={props.member} />
                            </div>
                        </Col>
                        
                        :""}
                    </Row>     
                               
                </Container>
            </section>
        </React.Fragment>
    )
}
export default About
