import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { Button, Form, Container, Row, Col, Table } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import GetCTA from "../../getcta";

import './Features.scss'

// markup
const Features = (props) => {
    
    return (
        <React.Fragment>
            <section className="features">
                
                    <Container>
                        <Row>
                            <Col>
                                <div className="box-wrapper">
                                    {props.boxlist.map((item, index) => (
                                        <div className="feature-box">
                                            <Link to="#" className="icons">
                                                <i className="home">
                                                </i>
                                            </Link>
                                            <h3> {item.Box_Item_Title}
                                            </h3>
                                            <p>{item.Box_Item_Caption}</p>
                                            {item.Box_Item_Custom_Link?
                                            <Link className="btn btn-primary" to={item.Box_Item_Custom_Link}>
                                            {item.Box_Item_Link_Label}
                                            </Link>
                                            :
                                            <GetCTA class="btn btn-primary" Label={item.Box_Item_Link_Label} link={item.Box_Item_Link && item.Box_Item_Link.Primary_URL} />
                                            }
                                        </div>
                                    ))}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                
            </section>

        </React.Fragment>
    )
}
export default Features