import React, {useState} from 'react';
import "slick-carousel/slick/slick.css";
// import "~slick-carousel/slick/slick-theme.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../scss/bootstrap-overrides.scss"
import "../scss/global.scss";
import "../scss/grunticon.scss";
import Header from '../Components/Header/Header';
import Footer from '../Components/Footer/Footer';
import Banner from '../Components/Home/Banner/Banner';
import About from '../Components/Home/About/About';
import BoostModule from '../Components/Home/AboutBoost/BoostModule';
import FullWidthTile from '../Components/Home/AboutBoost/FullWidthTile';
import RightTile from '../Components/Home/MortageServices/RightTile';
import LeftTile from '../Components/Home/WorkNumber/LeftTile';
import CustomerReview from '../Components/Home/CustomerReview/CustomerReview';
import Insights from '../Components/Home/Insights/Insights';
import ImageGrid from '../Components/Home/About/ImageGrid';
import Intro from '../Components/CarrierLanding/Intro';
import PropertyWorthModule from '../Components/how-much-property-worth-module';
import AuctionModule from '../Components/auction-blue-module';
import MarketProperty from '../Components/marketproperty-blue-module';
import AboutBoost from '../Components/Home/AboutBoost/AboutBoost';
import Stats from '../Components/stats';
import FeaturedProperty from '../Components/PropertyDetails/PropertyList/FeaturedProperty';
import FeaturedPropertyLet from '../Components/PropertyDetails/PropertyList/FeaturedPropertyLet';
import Tetstimonials from '../Components/PropertyDetails/Tetstimonials/Tetstimonials';
import StaticPage from '../Components/StaticModules/Static-Page'
import BlgoresTop from '../Components/StaticModules/AboutPage/About';
import BlgoresBottom from '../Components/StaticModules/Boost/Boost';
import BoxTileBlock from '../Components/StaticModules/Features/Features';
import BookAppointment from '../Components/ValuationForm/ValuationInfo/BookanAppointmentForm';
import FinanceBottom from '../Components/StaticModules/Advice/Advice';
import AuctionsTop from '../Components/StaticModules/Achieve/Achieve'
import AuctionsBottom from '../Components/StaticModules/SuccessfulSales/OurSales'
import NewsListing from '../Components/NewsListing'
import TeamListing from '../Components/OfficeDetails/Team/TeamListing';
import Vacancies from '../Components/CarrierLanding/Vacancies/Vacancies';
import CarVacancies from '../Components/CarrierLanding/Vacancies/CarVacancies';
import Connect from '../Components/CarrierLanding/Connect/Connect';
import AreaguideListing from '../Components/StaticModules/Sales/AreaguideListing'
import OfficeListing from '../Components/StaticModules/SalesLocation/SalesLocation';
import AvilableON from '../Components/available-on';
import TrustPilotList from '../Components/trust-pilot-list';
import FeefoReviews from '../Components/PropertyDetails/Tetstimonials/FeeFoTestimonials';
import Sitemap from '../Components/sitemap';
const Index=(props)=>{
    const GQLPage = props.GQLPage;
    const GQLModules = props.GQLModules;
    const GQLAreaGuides = props.GQLAreaGuides;
    const GQLOffices = props.GQLOffices;
    const GQLTeams = props.GQLTeams;
    const NewsletterModule = GQLModules.filter((Modules) => Modules.Select_Module != null && Modules.Select_Module === "Newsletter_Subscribe_Module" ).map(({Select_Module}) => ({Select_Module}));
    const AccordionModule = GQLModules.filter((Modules) => Modules.Accordion_List != null && Modules.Accordion_List.length > 0).map(({Accordion_List}) => ({Accordion_List}));
    const SubAccordionModule = GQLModules.filter((Modules) => Modules.Sub_Accordion != null && Modules.Sub_Accordion.length > 0).map(({Sub_Accordion}) => ({Sub_Accordion}));
    const pageclass = GQLPage.Page_Class?GQLPage.Page_Class:""
    const [Teamfilter, setTeamfilter] = useState("");
    const [Deptfilter, setDeptfilter] = useState("");

    const handleChange = (value) =>{
        setTeamfilter(value)
    }
    const handleChangeDept = (value) =>{
        setDeptfilter(value)
    }
    return (
        <React.Fragment>
            <div id={`body-bg-${GQLPage.Body_Background_Color?GQLPage.Body_Background_Color:"default"}`} className={(GQLPage.Select_Template === "Landing_Page")?"wrapper "+pageclass:(GQLPage.Select_Template === "Static_Page")?"wrapper static-body property-wrap "+pageclass:pageclass}>
            <div className={GQLPage.Select_Template==="Static_Page"?"inner-header":"home-header"}>
            <Header title={GQLPage.Title} pageclass={pageclass}/>
            </div>
            {GQLModules.map((Modules, i) => {
                return<>
                {Modules.__typename === "GLSTRAPI_ComponentComponentsBannerSlider" &&
                    <Banner slides={Modules.Banner_Slides}/>
                }    
                {Modules.__typename === "GLSTRAPI_ComponentComponentsIntro" &&                
                    <Intro breadcrumbtitle={GQLPage.Title} showsearch={Modules.Show_Search_Form} showreviews={Modules.Show_Reviews} title={Modules.Intro_Title} content={Modules.Intro_Content} image={Modules.Intro_Image} bannerimage={Modules.Intro_Image_Banner} ctas={Modules.Intro_CTA} filters={Modules.Intro_With_Filters} handleChange={handleChange} handleChangeDept={handleChangeDept}/>
                }      
                {Modules.__typename === "GLSTRAPI_ComponentComponentsShortIntro" &&
                    <About title={Modules.Short_Intro_Title} content={Modules.Short_Intro_Content} member={Modules.Select_Member}/>
                }
                {Modules.__typename === "GLSTRAPI_ComponentComponentsStaticPage" &&
                    <StaticPage staff={GQLPage.Select_Staff} accordion={AccordionModule} subaccordion={SubAccordionModule} sidebar={GQLPage.Article_With_Sidebar} title={Modules.Static_Intro_Title} content={Modules.Static_Intro_Content} highlightedcontent={Modules.Static_Intro_Highlight_Text} image={Modules.Static_Intro_Image} newslettermodule={NewsletterModule && NewsletterModule.length > 0 ?"shownewslettermodule":""}/>
                }
                {Modules.__typename === "GLSTRAPI_ComponentComponentsTwoColumnContent" &&
                    <AboutBoost leftcontent = {Modules.Left_Column_Content} rightcontent = {Modules.Right_Column_Content} />
                }
                {Modules.__typename === "GLSTRAPI_ComponentComponentsBalgoresBoostTop" &&                
                <BlgoresTop leftcontent={Modules.Balogres_Boost_Top_Left_Content} videourl={Modules.Balogres_Boost_Top_Right_Video_URL} rightimage={Modules.Balogres_Boost_Top_Right_Image} counter={Modules.Balgores_Boost_Counter}/>
                }
                {Modules.__typename === "GLSTRAPI_ComponentComponentsBalgoresBoostBottom" &&                
                <BlgoresBottom leftcontent={Modules.Balogres_Boost_Bottom_Left_Content} link={Modules.Balogres_Boost_Bottom_Link} linklabel={Modules.Balogres_Boost_Bottom_Link_Label} rightimage={Modules.Balogres_Boost_Bottom_Right_Image}/>
                }
                
                {Modules.__typename === "GLSTRAPI_ComponentComponentsImageGrids" &&
                    <ImageGrid grids={Modules.Grid_List}/>
                }
                {Modules.__typename === "GLSTRAPI_ComponentComponentsModules" && Modules.Select_Module === "Balgores_Boost_Module" &&
                    <BoostModule/>
                }
                {Modules.__typename === "GLSTRAPI_ComponentComponentsTileBlocks" &&
                    <>
                    {Modules.Image_Position === "Right" && Modules.Tile_Style !=="Full_Width" &&
                        <RightTile id={Modules.id} video={Modules.Tile_Youtube_Video_URL} accordion={Modules.Tile_Accordion}bgcolor={Modules.Tile_Background} style={Modules.Tile_Style} title={Modules.Tile_Title} content={Modules.Tile_Content} customlink={Modules.Tile_Custom_Link} label={Modules.Tile_Link_Label} link={Modules.Tile_Link} image={Modules.Tile_Image}/>
                    }
                    {Modules.Image_Position === "Left" && Modules.Tile_Style !=="Full_Width" &&
                        <LeftTile  video={Modules.Tile_Youtube_Video_URL} bgcolor={Modules.Tile_Background} style={Modules.Tile_Style} title={Modules.Tile_Title} content={Modules.Tile_Content} customlink={Modules.Tile_Custom_Link} label={Modules.Tile_Link_Label} link={Modules.Tile_Link} image={Modules.Tile_Image} counter={Modules.Tile_Counter}/>
                    }
                    {Modules.Image_Position === "Left" && Modules.Tile_Style ==="Full_Width" &&
                        <FullWidthTile video={Modules.Tile_Youtube_Video_URL} bgcolor={Modules.Tile_Background} style={Modules.Tile_Style} title={Modules.Tile_Title} content={Modules.Tile_Content} customlink={Modules.Tile_Custom_Link} label={Modules.Tile_Link_Label} link={Modules.Tile_Link} image={Modules.Tile_Image}/>
                    }
                    </>
                }
                
                {Modules.__typename === "GLSTRAPI_ComponentComponentsModules" && Modules.Select_Module === "What_Our_Customers_Say_Module" &&
                    <CustomerReview />
                }
                {Modules.__typename === "GLSTRAPI_ComponentComponentsModules" && Modules.Select_Module === "What_Our_Customers_Say_Grid_Module" &&
                    <Tetstimonials />
                }
                {Modules.__typename === "GLSTRAPI_ComponentComponentsModules" && Modules.Select_Module === "FeeFo_Carousel_Module" &&
                    <FeefoReviews />
                }
                {Modules.__typename === "GLSTRAPI_ComponentComponentsModules" && Modules.Select_Module === "Insights_Slider_Module" &&
                    <Insights />
                }
                {Modules.__typename === "GLSTRAPI_ComponentComponentsModules" && Modules.Select_Module === "TrustPilot_Reviews_List" &&
                    <TrustPilotList />
                }
                {Modules.__typename === "GLSTRAPI_ComponentComponentsModules" && Modules.Select_Module === "How_Much_Is_Your_Property_Worth_Module" &&
                    <PropertyWorthModule title={GQLPage.Title}/>
                }
                {Modules.__typename === "GLSTRAPI_ComponentComponentsModules" && Modules.Select_Module === "Auction_Module" &&
                    <AuctionModule/>
                }
                {Modules.__typename === "GLSTRAPI_ComponentComponentsModules" && Modules.Select_Module === "Market_Property_Module" &&
                    <MarketProperty/>
                }
                {Modules.__typename === "GLSTRAPI_ComponentComponentsAvailableOn" &&
                    <AvilableON appstorelink= {Modules.App_Store_Link} androidlink={Modules.Playstore_Link} images={Modules.Available_On_Grid_Images}/>
                }
                {Modules.__typename === "GLSTRAPI_ComponentComponentsStats" &&
                    <Stats stats={Modules.Stats_List}/>
                }
                {Modules.__typename === "GLSTRAPI_ComponentComponentsModules" && Modules.Select_Module === "Featured_Properties_Sales_Module" &&
                    <FeaturedProperty />
                }
                {Modules.__typename === "GLSTRAPI_ComponentComponentsModules" && Modules.Select_Module === "Featured_Properties_Lettings_Module" &&
                    <FeaturedPropertyLet />
                }
                {Modules.__typename === "GLSTRAPI_ComponentComponentsBoxTileBlock" &&
                    <BoxTileBlock boxlist={Modules.Box_Item_List}/>
                }
                {Modules.__typename === "GLSTRAPI_ComponentComponentsForms" && Modules.Select_Forms === "Book_An_Appointment_Form" &&
                    <BookAppointment/>
                }
                {Modules.__typename === "GLSTRAPI_ComponentComponentsForms" && Modules.Select_Forms === "Mortgage_Calculator_Form" &&
                    <StaticPage page="calculator" sidebar={GQLPage.Article_With_Sidebar} />
                }
                {Modules.__typename === "GLSTRAPI_ComponentComponentsFinancialServicesBottomSection" &&
                    <FinanceBottom leftcontent={Modules.Financial_Services_Bottom_Left_Content} rightcontent={Modules.Financial_Services_Bottom_Right_Content} rightimage={Modules.Financial_Services_Bottom_Right_Image} greyblockcontent={Modules.Financial_Services_Bottom_Grey_Block_Content}/>
                }
                {Modules.__typename === "GLSTRAPI_ComponentComponentsAuctionsPage" &&
                    <AuctionsTop leftcontent={Modules.Auctions_Top_Left_Content} lotslink={Modules.View_Lots_Link} onlineautionlink={Modules.Online_Auction_Link} upcomingauctions={Modules.Upcoming_Auctions} nextauctiondate={Modules.Next_Auction_Date}/>
                }
                {Modules.__typename === "GLSTRAPI_ComponentComponentsSuccessfulSale" &&
                    <AuctionsBottom heading={Modules.Section_Heading} successlist={Modules.Successful_Sale_List} cta1label={Modules.Section_Bottom_CTA_Label1} cta2label={Modules.Section_Bottom_CTA_Label2} cta1link={Modules.Section_Bottom_CTA_Link1} cta2link={Modules.Section_Bottom_CTA_Link2}/>
                }
                {/* Listing Modules Start */}
                {Modules.__typename === "GLSTRAPI_ComponentComponentsModules" && Modules.Select_Module === "News_Listing_Page" &&
                    <NewsListing />
                }
                {Modules.__typename === "GLSTRAPI_ComponentComponentsModules" && Modules.Select_Module === "Team_Listing_Page" &&
                    <TeamListing Teamfilter={Teamfilter} Deptfilter={Deptfilter} teams={GQLTeams}/>
                }
                {Modules.__typename === "GLSTRAPI_ComponentComponentsModules" && Modules.Select_Module === "Career_Listing_Page" &&
                    <Vacancies/>
                }
                {Modules.__typename === "GLSTRAPI_ComponentComponentsModules" && Modules.Select_Module === "Car_Leasing_Vacancy_Page" &&
                    <CarVacancies/>
                }
                {Modules.__typename === "GLSTRAPI_ComponentComponentsForms" && Modules.Select_Forms === "Carreer_Form" &&
                    <Connect member={GQLPage.Select_Staff}/>
                }
                {Modules.__typename === "GLSTRAPI_ComponentComponentsModules" && Modules.Select_Module === "Areaguide_Listing_Page" &&
                    <AreaguideListing GQLAreaGuides={GQLAreaGuides}/>
                }
                {Modules.__typename === "GLSTRAPI_ComponentComponentsModules" && Modules.Select_Module === "Office_Listing_Page" &&
                    <OfficeListing GQLOffices={GQLOffices}/>
                }
                {Modules.__typename === "GLSTRAPI_ComponentComponentsModules" && Modules.Select_Module === "Sitemap" &&
                    <Sitemap/>
                }
                {/* Listing Modules End */}
                </>
             })}
            <Footer popularSearch={GQLPage.Select_Popular_Search}/>
            </div>
        </React.Fragment>
    )
}
export default Index;
